<template>
  <div class="screen-wrap">
    <div class="container-in sheet" id="layer">
      In coming... Process Form
    </div>
  </div>
</template>
<script>
  let tokenQuote = null
  
  //Tools
  
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import { popUp } from '../../store/tools'
  
  //import html2pdf from 'html2pdf'
  //Components
  import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
  //GRAPHQL
  import {  } from '../../graphql/mutations'
  import { smd_formProcess } from '../../graphql/queries'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
    },
    data() {
      return {
        record:null,
        response: null,
        
      }
    },
    created() {
      console.log(this.$router.currentRoute.value.query);
      this.formProcess()
    },
    methods: {
      ...Vuex.mapActions([]),
      ...Vuex.mapMutations([]),
      async formProcess(){
        let pushData = this.$router.currentRoute.value.query
        let pullData = null
        try {
          pullData = await API.graphql({
            query: smd_formProcess,
            variables: pushData
          })
          pullData = pullData.data.smd_formProcess
          if (pullData.smd_response.statusCode != 200) {
            popUp(pullData.smd_response, 'response', 'err')
            loading('hidde', 'getItem')
            return
          }
          else if (pullData.smd_response.popAlert == true) {
            popUp(pullData.smd_response, 'response', 'scc')
          }
          console.log(pullData);
          document.getElementById('layer').innerHTML = pullData.data
        } catch (error) {
          console.log(error);
          popUp(error, 'cach', 'err')
        }
      }
    },
    computed:{
      ...Vuex.mapState(['publicOrganization', 'publicOrganizationData','publicOrganization', 'reload', 'user','publicOrgPay','dataUser','quiz','organizationData'])
    }
  }
</script>
<style lang="less">
</style>